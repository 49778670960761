body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #27293c!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo-title {
  font-size: 50px;
  color: white
}

.custom-bold {
  font: bold;
  font-weight: 500!important;
}

.signIn-div {
  display: flex;
  width: 80%;
  height: 19vh;
  background-color: #1e1e2e;
  border-radius: 10px;
}

.card-title {
  font-weight: 0;
  font-size: 30px;
  margin:2px;
  color: white;
}

.card-content {
  font-size: 15px;
  color: white;
}

.makeStyles-signinButton-3 { 
  width: 100%!important;
}

.makeStyles-signinButton-23 {
  width: 100%!important;
}

.makeStyles-signinButton-39 { 
  width: 100%!important;
}

.makeStyles-signinButton-87 {
  width: 100%!important;
}

.makeStyles-signinButton-103 {
  width: 100%!important;
}

.makeStyles-signinButton-119 {
  width: 100%!important;
}

@media screen and (max-width: 1600px) {
  .card-title {
    font-size: 20px;
  }
  .signIn-div {
    height: 15vh;
  }
  #logo1, #logo2 {
    width: 17vh!important;
  }
}


@media screen and (max-width: 1420px) {
  .MuiButton-root {
    font-size: 0.7rem;
  }
  .MuiButton-containedSizeLarge {
    font-size: 0.7rem!important;
  }
}

@media screen and (max-width: 1250px) {
  #logo1, #logo2 {
    width: 14vh!important;
  }
}

@media screen and (max-width: 1060px) { 
  .signIn-div {
    width: 90%;
  }
}

@media screen and (max-width: 960px) { 
  .signIn-div {
    height: 18vh;
    width: 55%;
  }
}

@media screen and (max-width: 760px) { 
  .signIn-div {
    width: 60%;
  }
}

@media screen and (max-width: 690px) {
  
  .signIn-div {
    width: 70%;
  }
}

@media screen and (max-width: 600px) {
  
  .signIn-div {
    width: 80%;
  }
}


@media screen and (max-width: 525px) {
  .card-title {
    font-size: 1rem;
  }
  .card-content {
    font-size: 0.8rem;
  }
  .signIn-div {
    width: 90%;
  }
}

@media screen and (max-width: 500px) {
  
  .signIn-div {
    width: 90%;
  }
}

@media screen and (max-width: 455px) {
  .MuiGrid-grid-xs-4 {
    flex-basis: 0%!important;
  }
  .MuiGrid-grid-xs-8 {
    max-width: 100%!important;
    flex-basis: 100%!important;
  }
}

@media screen and (max-width: 290px) {
  .card-title, .card-content {
    display: none;
  }
}

@media screen and (max-height: 740px) {
  .signIn-div {
    height: 19vh;
  }
}

@media screen and (max-height: 650px) {
  .card-title {
    font-size: 1rem;
  }
  .card-content {
    font-size: 0.8rem;
  }
  .MuiGrid-grid-xs-4 {
    flex-basis: 30%!important;
  } 
  .MuiGrid-grid-xs-8 {
    max-width: 100%;
  }
}

@media screen and (max-height: 524px) {
  .card-content {
    display: none;
  }
}

@media screen and (max-height: 450px) {
  .card-content, .card-title {
    display: none;
  }
}
